import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function Projects({ data })  {
    const projects = data.projects.nodes;

    return (
        <Layout>
            <SEO title="Projects" />

            <h2 className="text-center">Projects</h2>

            <div className="container px-5 mx-5">
                {projects.map(project => (
                    <div className="row" id={project.frontmatter.title}  style={{paddingTop: 70}}>
                        <div className="col-4 ps-2">
                            {project.frontmatter.thumb ?
                                <Img fluid={project.frontmatter.thumb.childImageSharp.fluid}
                                     style={{ width: 300 }} className="mx-auto rounded shadow-sm float-end" />
                                : ""
                            }
                        </div>
                        <div className="col-8 pe-5">
                            <div className="fs-3 lead text-black">{project.frontmatter.title}</div>
                            <div className="d-flex">
                                {project.frontmatter.tags.split(",").map(tag => (
                                    <div className="font-monospace me-5">{tag}</div>
                                ))}
                            </div>
                            <div className="mt-2 w-75" style={{textAlign: "justify"}}>{project.frontmatter.abstract}</div>
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    );
}

export const query = graphql`
query ProjectPage {
  projects: allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {fileAbsolutePath: {regex: "/(src/projects)/"}}
    ) {
    nodes {
      frontmatter {
        title
        tags
        abstract
        thumb {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fileAbsolutePath
    }
  }

}
`
